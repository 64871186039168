import React from "react";
import { MESSAGE_TYPES } from "../constants";
import { AI_ICON, HUMAN_ICON } from '../svg';

/** ChatMessage
 *
 * An individual chat message.
 *
 * @props {string} key - Unique identifier for the message.
 * @props {string} message_type - Type of message ("HUMAN" or "AI").
 * @props {string} content - Text content of the message.
 * @props {string} userAvatarUrl - User's avatar URL.
 */
const ChatMessage = function (props) {
  const icon =
    props.message_type === MESSAGE_TYPES.HUMAN
      ? HUMAN_ICON(props.user.avatar_url)
      : AI_ICON();
  const className =
    props.message_type === MESSAGE_TYPES.HUMAN
      ? "gf-chat--message--user"
      : "gf-chat--message--system";

  return (
    <div className={className}>
      <div className="gf-chat--message--icon">
        {icon}
      </div>
      <div className="gf-chat--message--contents">
        <div className="gf-chat--message--contents--header">
          {props.message_type === MESSAGE_TYPES.HUMAN
            ? props.user.full_name
            : "Fig"}
        </div>
        <div className="gf-chat--message--contents--text">
          {props.content}
        </div>
      </div>
    </div>
  );
};

export default ChatMessage;