import { Cookies } from "../app";
import { INIT_URL } from "./constants";

/** sendMessage(apiUrl, message, callback) ---------------------
 *
 * Sends a user message to the specified API endpoint.
 *
 * @param {string} apiUrl - The API endpoint URL.
 * @param {object} message - The message data object to send.
 * @param {function} callback - Handles the response from the API.
 */
export const sendMessage = (apiUrl, message, callback) => {
  fetch(apiUrl, {
    method: "POST",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      "X-CSRFToken": Cookies.get("csrftoken"),
    },
    body: JSON.stringify(message),
  }).then((response) => {
    if (response.ok) {
      callback(response.json());
    }
  });
};

/** fetchMessage(apiUrl, callback) -----------------------------
 *
 * Fetches a message from the specified API endpoint.
 *
 * @param {string} apiUrl - The API endpoint URL.
 * @param {function} callback - Handles the response from the API.
 */
export const fetchMessage = (apiUrl, callback) => {
  fetch(apiUrl, {
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      "X-CSRFToken": Cookies.get("csrftoken"),
    },
  }).then((response) => {
    if (response.ok) {
      callback(response.json());
    }
  });
};

/** fetchInitalData() ------------------------------------------
 *
 * Fetches a current user, project, and team data from the backend.
 *
 * Returns:
 * {
 *   "user": {
 *     "id": 1,
 *     "full_name": "Violet Kester",
 *     "avatar_url": "https://storage.googleapis.com/..."
 *   },
 *   "project": {
 *     "id": 52,
 *     "pinned_dashboard_id": 1,
 *     "summary": "Here's today's summary.",
 *     "summary_updated_at": "2025-02-05T12:00:00Z"
 *   },
 *   "team": {
 *     "id": 33,
 *     "dataset_id": "violet_team_000033_tables"
 *   }
 * }
 */
export const fetchInitalData = async () => {
  const response = await fetch(INIT_URL, {
    method: "GET",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      "X-CSRFToken": Cookies.get("csrftoken"),
    }
  });

  if (!response.ok) {
    throw new Error("Failed to fetch initial data for current user and project");
  }

  const data = await response.json();

  return data;
};