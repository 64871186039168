import React, { useState, useEffect } from "react";
import { sendMessage, fetchMessage, fetchInitalData } from "../api";
import {
  MESSAGE_TYPES,
  SUMMARY_URL,
  SUMMARY_HISTORY_ADD_URL,
  TASK_URL,
  WELCOME_URL,
} from "../constants";
import ChatMessages from "./ChatMessages";
import ChatInputBar from "./ChatInputBar";
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

/** Figbot
 *
 * The main Figbot component.
 *
 * @state {string} currentPath - Current URL pathname.
 * @state {object} currentProject - Current project's data.
 * @state {object} currentTask - Current user message being sent to the API.
 * @state {object} currentTeam - Current team's data.
 * @state {object} currentUser - Current user's data.
 * @state {string} inputMessage - Current message being typed by the user.
 * @state {boolean} isLoading - Indicates whether component is loading data.
 * @state {boolean} isVisible - Indicates whether chat sidebar is visible.
 * @state {Array} messages - An array of all chat message objects.
 */
const Figbot = function () {
  const [currentPath, setCurrentPath] = useState(window.location.pathname);
  const [currentProject, setCurrentProject] = useState(null);
  const [currentTask, setCurrentTask] = useState(null);
  const [currentTeam, setCurrentTeam] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [inputMessage, setInputMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [lastMessageSent, setLastMessageSent] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [messages, setMessages] = useState([]);

  // Side effect:
  // Set `isVisible` to `true` when chat sidebar is expanded
  useEffect(() => {
    const chatWindow = document.querySelector(".gf-sidebar--chat--window");
    if (!chatWindow) return;

    const observer = new ResizeObserver(() => {
      const style = window.getComputedStyle(chatWindow);
      setIsVisible(
        style.visibility === "visible" &&
        style.opacity !== "0" &&
        style.pointerEvents !== "none"
      );
    });

    observer.observe(chatWindow);
    return () => observer.disconnect();
  }, []);

  // Side effect:
  // Monitor URL changes to detect user, project, and team switches
  useEffect(() => {
    const handleLocationChange = () => {
      if (window.location.pathname !== currentPath) {
        setCurrentPath(window.location.pathname);
      }
    };

    window.addEventListener('popstate', handleLocationChange);
    return () => window.removeEventListener('popstate', handleLocationChange);
  }, [currentPath]);

  // Side effect:
  // Fetch user, project, and team data when chat becomes visible
  useEffect(() => {
    const initializeData = async () => {
      try {
        // Only fetch if we don't have data yet or path has changed
        if (!currentUser || !currentProject || !currentTeam || window.location.pathname !== currentPath) {
          const data = await fetchInitalData();
          setCurrentUser(data.user);
          setCurrentProject(data.project);
          setCurrentTeam(data.team);
          setCurrentPath(window.location.pathname);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (isVisible) {
      initializeData();
    }
  }, [isVisible]);

  // Side effect:
  // Load messages when chat becomes visible
  useEffect(() => {
    // Fetch welcome message if no messages exist
    if (isVisible && messages.length === 0) {
      fetchWelcomeMessage({
        addMessage,
        sendMessageCallback,
      });
    }
    // Fetch summary if user clicks "open in chat" from the homepage
    const handleLoadSummary = () => {
      setIsLoading(true);
      getSummary();
      sendMessage(
        SUMMARY_HISTORY_ADD_URL,
          null,
          () => {},
      );
    };

    // Set up summary event listener
    document.addEventListener("loadSummary", handleLoadSummary);
    return () => document.removeEventListener("loadSummary", handleLoadSummary);
  }, [isVisible, messages.length, currentProject]);

  // Side effect:
  // Call completeTask() when the value of `currentTask` changes
  useEffect(() => {
    if (currentTask) {
      completeTask({
        addMessage,
        currentTask,
        setCurrentTask,
        setIsLoading,
        sendMessageCallback,
      });
    }
  }, [currentTask]);

  // Fetch the welcome message from the API and add it to chat messages
  function fetchWelcomeMessage({
    addMessage,
    sendMessageCallback,
  }) {
    try {
      fetchMessage(WELCOME_URL, sendMessageCallback);
    } catch (error) {
      addMessage(getErrorMessage(error));
    }
  }

  // Get summary message from current project
  function getSummary() {
    const summary = currentProject.summary;
    addMessage({
      message_type: MESSAGE_TYPES.AI,
      content: (
        <ReactMarkdown remarkPlugins={[remarkGfm]}>
          {summary}
        </ReactMarkdown>
      ),
    });
    setIsLoading(false);
  }

  // Add a message object to `messages` in state
  const addMessage = (message) => {
    const newMessages = [...messages, message];
    setMessages(newMessages);
  };

  // Create a message object from a user message
  const createUserMessage = (messageContent) => {
    return {
      content: messageContent,
      message_type: MESSAGE_TYPES.HUMAN,
      timestamp: Date.now(),
    };
  };

  // After a user message is sent,
  // Add the AI response to `messages` in state and set `isLoading` to `false`
  const sendMessageCallback = (resultPromise) => {
    resultPromise.then((result) => {
      const parsedResult = JSON.parse(result);
      parsedResult.content = (
        <ReactMarkdown remarkPlugins={[remarkGfm]}>
          {parsedResult.content}
        </ReactMarkdown>
      );
      addMessage(parsedResult);
      setIsLoading(false);
    });
  };

  // Complete task by POSTing it to the API and handling the server response
  // Resets `currentTask`/`inputMessage` and sets `isLoading` to `false`
  function completeTask({
    currentTask,
    setCurrentTask,
    addMessage,
    sendMessageCallback,
    setIsLoading,
  }) {
    setIsLoading(true);
    setLastMessageSent(Date.now());
    try {
      sendMessage(
        TASK_URL(currentProject.id),
        currentTask,
        sendMessageCallback
      );
    } catch (error) {
      addMessage(getErrorMessage(error));
      setIsLoading(false);
    }
    setCurrentTask(null);
  }

  // Log error and add to chat messages
  function getErrorMessage(error) {
    console.error("Error fetching the data:", error);
    return {
      message_type: MESSAGE_TYPES.AI,
      content: (
        <p className="pg-text-danger">
          Sorry something went wrong. This may be an OpenAI error, or your
          API key may not be set properly. If you are a site administrator
          seeing this for the first time, double check your
          <code>OPENAI_API_KEY</code> setting / environment variable and
          restart all running processes.
        </p>
      ),
    };
  }

  // Controller function for sending a user message to the server
  // Called when the user presses the "Send" button
  const sendMessageController = (messageContent) => {
    let message = createUserMessage(messageContent);
    addMessage(message);
    setCurrentTask(message);
    setInputMessage("");
  };

  // Return nothing if chat isn't visible
  if (!isVisible) return null;

  return (
    <>
      <ChatMessages
        messages={messages}
        isLoading={isLoading}
        lastMessageSent={lastMessageSent}
        user={currentUser}
      />
      <ChatInputBar
        inputMessage={inputMessage}
        setInputMessage={setInputMessage}
        handleSendMessage={sendMessageController}
        isLoading={isLoading}
      />
    </>
  );
};

export default Figbot;