import React from "react";
import { SEND_ICON } from "../svg";
/** ChatInputBar
 *
 * The chat input area where users type and send their messages.
 *
 * @props {string} inputMessage - The message being typed by the user.
 * @props {function} setInputMessage - Updates the input message in state.
 * @props {function} handleSendMessage - Sends the current input message.
 * @props {boolean} isLoading - Indicates whether the component is loading data.
 */
const ChatInputBar = function (props) {
  const handleKeyUp = (event) => {
    if (event.key === "Enter") {
      props.handleSendMessage(props.inputMessage);
    }
  };

  return (
    <div className="gf-chat--input-bar">
      <input
        name="message"
        type="text"
        placeholder="Type your message..."
        aria-label="Message"
        className="gf-input--text--large"
        value={props.inputMessage}
        onChange={(event) => props.setInputMessage(event.target.value)}
        onKeyUp={handleKeyUp}
      ></input>
      <button
        type="submit"
        className="gf-button--neutral--large gf-full-height"
        onClick={() => props.handleSendMessage(props.inputMessage)}
      >
        {SEND_ICON()}
      </button>
    </div>
  );
};

export default ChatInputBar;