/* Message types ----------------------------- */

const MESSAGE_TYPES = {
  HUMAN: "HUMAN",
  AI: "AI",
};

/* API URLs ---------------------------------- */

const FIG_BOT_BASE_URL = "/figbot";

const INIT_URL = `${FIG_BOT_BASE_URL}/init`;
const WELCOME_URL = `${FIG_BOT_BASE_URL}/welcome`;
const SUMMARY_URL = `${FIG_BOT_BASE_URL}/summary`;
const SUMMARY_HISTORY_ADD_URL = `${FIG_BOT_BASE_URL}/summary/add-to-history`;
const TASK_URL = (projectId) => `${FIG_BOT_BASE_URL}/${projectId}/task`;

export { MESSAGE_TYPES, INIT_URL, SUMMARY_URL, TASK_URL, WELCOME_URL, SUMMARY_HISTORY_ADD_URL };