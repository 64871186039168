import React, { useEffect, useState } from "react";
import ChatMessage from "./ChatMessage";

/** ChatMessages
 *
 * The chatbox container where all chat messages appear.
 *
 * @props {Array} messages - All chat messages to be displayed.
 * @props {boolean} isLoading - Indicates whether the component is loading data.
 * @props {string} userAvatarUrl - User's avatar URL.
 */

const defaultThinkingMessage = "Thinking";


const ChatMessages = function (props) {
  const [lastMessageSent, setLastMessageSent] = useState(props.lastMessageSent);
  const [thinkingMessage, setThinkingMessage] = useState(getThinkingMessageContent(props.lastMessageSent));
  const [dots, setDots] = useState(""); // For the dot animation

  useEffect(() => {
    setLastMessageSent(props.lastMessageSent);
  }, [props.lastMessageSent, props.isLoading]);


  useEffect(() => {
    let animationInterval;
    setThinkingMessage(defaultThinkingMessage);

    // Create the dots animation if the component is loading
    if (props.isLoading) {
      animationInterval = setInterval(() => {
        setDots((prev) => {
          // Cycle between "", ".", "..", "..."
          if (prev.length === 3) return "";
          return prev + ".";
        });
      }, 500);
    } else {
      setDots(""); // Reset the dots when loading stops
    }

    // Cleanup interval on unmount or when isLoading changes
    return () => clearInterval(animationInterval);
  }, [props.isLoading]);

  function setNextThinkingMessage() {
    if (!props.isLoading) {
      setThinkingMessage(defaultThinkingMessage);
      return;
    }

    const nextMessage = getThinkingMessageContent();
    if (thinkingMessage !== nextMessage) {
      setThinkingMessage(nextMessage);
    }
  }

  function getThinkingMessageContent() {
    if (!lastMessageSent || !props.isLoading) return defaultThinkingMessage;

    const differenceInSeconds = Math.floor((Date.now() - new Date(lastMessageSent).getTime()) / 1000);
    const thinkingMessageTimeMap = {
      3: defaultThinkingMessage,
      6: "Generating query",
      9: "Processing data",
    };

    let message = "Cleaning it all up";

    for (let [seconds, nextMessage] of Object.entries(thinkingMessageTimeMap)) {
      const timeBoundary = parseInt(seconds, 10);
      if (differenceInSeconds <= timeBoundary) {
        message = nextMessage;
        setTimeout(() => {
          if (!props.isLoading) {
            setThinkingMessage(defaultThinkingMessage);
            return;
          }
          setNextThinkingMessage();
          }, (timeBoundary - differenceInSeconds) * 1000);
        break;
      }
    }

    return message;
  }

  return (
    <div id="gf-chat--messages" className="gf-chat--messages">
      {props.messages.map((message, index) => (
        <ChatMessage key={index} {...message} user={props.user} />
      ))}
      {props.isLoading ? (
        // Add the animated dots to the thinkingMessage with italics
        <ChatMessage key="thinking-message" content={<span style={{ fontStyle: 'italic' }}>{`${thinkingMessage}${dots}`}</span>} message_type="AI" />
      ) : null}
    </div>
  );
};

export default ChatMessages;