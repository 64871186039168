import React, { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import Figbot from "./components/Figbot";

document.addEventListener('DOMContentLoaded', () => {
  const figbotElement = document.getElementById("gf-figbot");
  if (figbotElement) {
    const root = createRoot(figbotElement);
    root.render(
      <StrictMode>
        <Figbot />
      </StrictMode>
    );
  }
});