"use strict";
import * as React from "react";

const AI_ICON = function () {
  return (
    <svg
      id="a"
      width="2rem"
      height="2rem"
      viewBox="0 0 121.21899 121.219"
      version="1.1"
    >
      <defs
        id="defs195" />
      <path
        d="m 106.84998,52.062 11.339,4.196 c 4.04,1.495 4.04,7.208 0,8.703 l -11.339,4.196 C 89.386977,75.619 75.618977,89.387 69.156977,106.85 l -4.196,11.339 c -1.495,4.04 -7.208,4.04 -8.703,0 l -4.196,-11.339 c -6.462,-17.463 -20.23,-31.231 -37.693,-37.693 L 3.0299766,64.961 c -4.04,-1.495 -4.04,-7.208 0,-8.703 L 14.368977,52.062 c 17.463,-6.462 31.231,-20.23 37.693,-37.693 l 4.196,-11.339 c 1.495,-4.04 7.208,-4.04 8.703,0 l 4.196,11.339 c 6.462,17.463 20.23,31.231 37.693003,37.693 z"
        fill="#3f2946"
        id="path182" />
      <path
        d="M 68.890977,265.085 -89.794023,34.018 C -115.84302,-3.913 -168.80802,-11.353 -204.29902,17.934 l -216.254,178.446 c -57.38,47.348 -94.965,114.455 -105.362,188.118 l -0.296,2.098 c -19.974,141.516 78.604,272.414 220.133,292.304 l 131.943,18.543 c 141.469997,19.882 272.271997,-78.684 292.154,-220.155 l 0.299,-2.126 c 10.364,-73.742 -7.27,-148.692 -49.427003,-210.078 z M -382.97702,372.796 c 2.943,-20.94 26.18,-32.211 44.446,-21.558 l 54.142,31.573 c 3.178,1.852 2.507,6.627 -1.059,7.532 l -60.748,15.427 c -20.496,5.205 -39.725,-12.034 -36.782,-32.975 z m 116.842,80.025 -32.047,53.864 c -10.812,18.173 -36.599,19.581 -49.325,2.693 v 0 c -12.726,-16.887 -4.265,-41.288 16.184,-46.673 l 60.61,-15.958 c 3.557,-0.937 6.459,2.913 4.578,6.074 z m 12.385,-120.866 -53.863,-32.047 c -18.173,-10.812 -19.58,-36.599 -2.692,-49.326 16.888,-12.725 41.288,-4.265 46.672,16.185 l 15.958,60.61 c 0.937,3.557 -2.914,6.459 -6.075,4.578 z m 58.45,295.942 c -19.157,0.308 -33.96,-16.797 -31.293,-35.769 l 54.627,-388.688 c 2.666,-18.972 21.611,-31.334 39.94,-25.759 106.531997,32.408 176.606997,138.393 160.621997,252.129 -15.984,113.736 -112.558,196.3 -223.895997,198.088 z"
        fill="#3f2946"
        id="path190" />
    </svg>
  );
};

const HUMAN_ICON = function (userAvatarUrl) {
  if (userAvatarUrl) {
    return (
      <img
        src={userAvatarUrl}
        alt="User avatar"
        style={{
          width: "40px",
          height: "40px",
          borderRadius: "50%",
          objectFit: "cover"
        }}
      />
    );
  }
};

const SEND_ICON = function () {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.99972 10L1.2688 1.12451C7.88393 3.04617 14.0276 6.07601 19.4855 9.99974C14.0276 13.9235 7.884 16.9535 1.26889 18.8752L3.99972 10ZM3.99972 10L11.5 10"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export { AI_ICON, HUMAN_ICON, SEND_ICON };
